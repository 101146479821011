import {useEffect, useState} from "react";
import styled from "styled-components";
import tw from 'twin.macro';

const Clusters = ({selected, setSelected}) => {
    const [clusters, setClusters] = useState(() => {
        return []
    })

    useEffect(() => {
        fetch('https://twitch.ellezelin.com/api/clusters')
            .then((response) => response.json())
            .then(setClusters)
            .catch(console.error)
    }, [])


    return (<div className={(selected === null ? '' : 'h-0 opacity-0') + ' flex place-self-center'}>
        <Container>
            {clusters && clusters.map((cluster) => {
                return (<Cluster key={'cluster-' + cluster.id} onClick={() => setSelected(cluster.id)}>
                    <Title>{cluster.name}</Title>
                    <Logo style={{
                        backgroundImage: 'url(https://twitch.ellezelin.com/api/cluster/logo?id=' + cluster.id + ')'
                    }}/>
                </Cluster>)
            })}
            {/*<Cluster>*/}
            {/*    <Title>Personalisé</Title>*/}
            {/*</Cluster>*/}
        </Container>
    </div>)
}

const Container = styled.div`
    ${tw`duration-500 h-full overflow-hidden m-10 grid grid-cols-2 place-content-center place-items-center gap-4 transition-all`}
`
const Cluster = styled.div`
    ${tw`bg-twitch hover:bg-twitch-hover pb-2 px-4 pt-1 flex flex-col rounded-lg drop-shadow-md cursor-pointer`}
`
const Title = styled.div`
    ${tw`text-center text-white`}
`
const Logo = styled.div`
    width: 160px;
    height: 160px;
    ${tw`bg-contain bg-no-repeat bg-center mt-1`}
`

export default Clusters
