import CountMenu from "./CountMenu";
import StreamsMenu from "./StreamsMenu";
import LayoutMenu from "./LayoutMenu";
import {useEffect, useState} from "react";
import LoadingIcon from "./LoadingIcon";

const Menu = ({cluster, setCluster, lives, setLives, chat, setChat, layout, setLayout, layouts}) => {
    const [streams, setStreams] = useState(() => {
        return {timeout: 10, data: null}
    })

    useEffect(() => {
        if (cluster === null) {
            return
        }
        const timer = setTimeout(() => {
            fetch('https://twitch.ellezelin.com/api/streams?cluster_id=' + cluster)
                .then((response) => response.json())
                .then((json) => {
                    setStreams({timeout: 60000, data: json})
                })
                .catch(console.error)
        }, streams.timeout)

        return () => {
            clearTimeout(timer)
        }
    })

    if (!cluster) {
        return (<div/>)
    }

    const colors = [
        'fill-red-500',
        'fill-orange-500',
        'fill-amber-500',
        'fill-yellow-500',
        'fill-lime-500',
        'fill-green-500',
        'fill-emerald-500',
        'fill-teal-500',
        'fill-cyan-500',
        'fill-sky-500',
        'fill-blue-500',
        'fill-indigo-500',
        'fill-violet-500',
        'fill-purple-500',
    ]
    const step = 15

    if (cluster && streams.data === null) {
        return (<div className='grid place-content-center relative'>
            <div className="relative w-96 h-96 mb-10">
                {colors.map((color, index) => {
                    const delay = (colors.length - index) * step
                    return (
                        <LoadingIcon color={color} delay={delay + 'ms'} key={'loader-' + index}/>)
                })}
                <LoadingIcon color="fill-twitch" delay="0ms"/>
            </div>
        </div>)
    }

    return (<div className="flex">
        <CountMenu lives={lives} setLives={setLives} setCluster={setCluster} setStreams={setStreams} setChat={setChat}
                   online={streams.data !== null && streams.data.length || 0}/>
        <StreamsMenu lives={lives} setLives={setLives} chat={chat} setChat={setChat} data={streams.data || []}/>
        <LayoutMenu lives={lives} layouts={layouts} layout={layout} setLayout={setLayout}/>
    </div>)
}

export default Menu
