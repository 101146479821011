import Stream from "./Stream"
import {useEffect, useState} from "react"
import { useInputEvent } from "../useInputEvent"

const StreamsMenu = ({data, lives, setLives, chat, setChat}) => {
    const [count, setCount] = useState(0)
    const key = useInputEvent()
    const hasLive = lives.length > 0
    const code = [ "ArrowUp", "ArrowUp", "ArrowDown", "ArrowDown", "ArrowLeft", "ArrowRight", "ArrowLeft", "ArrowRight", "b", "a",];

    useEffect(() => {
        if (key == null) return
        console.log(key)
        if (key !== code[count]) {
            setCount(0)
            return
        }

        setCount((state) => state + 1)
        if (count + 1 === code.length) {
            setLives(data)
            setCount(0)
        }
    }, [key])

    return (<div className={[
        'grow flex transition-all',
        hasLive && 'content-start items-center overflow-y-visible overflow-x-scroll hide-scrollbar',
        !hasLive && 'flex-wrap place-content-center gap-3 p-10',
        'text-white text-center text-xs',
    ].join(' ')}>
        {data && data.map((stream) => {
            return (<Stream key={'stream-' + stream.id} stream={stream}
                            lives={lives} setLives={setLives}
                            chat={chat} setChat={setChat}/>)
        })}
    </div>)
}

export default StreamsMenu
