import logo from "../failyvlogo.png";
import Chat from "./Chat";
import Lives from "./Lives";

const Player = ({layouts, layout, lives, chat}) => {
    return (<div className={`grow flex justify-center`}>
        {lives && lives.length > 0 && (<Lives layouts={layouts} layout={layout} lives={lives}/>)}
        {chat && chat !== '' && (<Chat chat={chat}/>)}
        {(!lives || lives.length <= 0) && (<div/>)}
    </div>)
}

export default Player
