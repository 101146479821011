import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons'

const CountMenu = ({online, lives, setLives, setCluster, setStreams, setChat}) => {
    const classes = (lives.length > 0 ? 'items-center' : 'items-start pt-10 pl-10 ') + ' flex flex-col text-white px-1 text-center text-xs my-2 select-none'

    return (<div className={classes}>
        {lives.length <= 0 && (
            <div className="bg-twitch p-1 rounded mb-2 px-1.5 cursor-pointer" onClick={() => {
                setLives([])
                setStreams({timeout: 10, data: null})
                setCluster(null)
            }}>
                RETOUR
            </div>
        )}
        {online > 0 && (<div className="self-center">
            <div className="p-1 rounded bg-red-500">
                <span className="w-2 h-2 inline-block relativ mr-2.5">
                    <span className="w-2 h-2 absolute inline-block rounded-full bg-white"/>
                    <span className="w-2 h-2 absolute inline-block rounded-full bg-white animate-ping"/>
                </span>
                <span>LIVE</span>
            </div>
            <div className="flex mx-2 px-1 rounded-b bg-red-900 place-content-center">
                <div className="px-0.5">{online}</div>
                {lives && lives.length > 0 && (<div className="px-0.5 w-3">
                    <div className="cursor-pointer" onClick={() => {
                        setLives([])
                        setChat('')
                    }}>
                        <FontAwesomeIcon icon={faTrashAlt}/>
                    </div>
                </div>)}
            </div>
        </div>)}
        {online <= 0 && (<div className="p-1 rounded my-1 bg-slate-600">OFFLINE</div>)}
    </div>)
}

export default CountMenu
