import styled from "styled-components"
import tw from "twin.macro"
import {Component} from "react"

class Live extends Component {
    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    render() {
        const muted = this.props.index === 0 ? 'false' : 'true'
        const url = `https://player.twitch.tv/?channel=${this.props.channel}&parent=${window.location.hostname}&muted=${muted}&autoplay=true`
        //console.log(url)
        return (<Frame title="Twitch" src={url}/>)
    }
}

const Frame = styled.iframe`
    ${tw`w-full h-full`}
`

export default Live
