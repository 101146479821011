import styled from "styled-components"
import tw from "twin.macro"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus, faChevronRight, faTimes} from '@fortawesome/free-solid-svg-icons'
import {useState} from "react";

const CustomPlayerSettings = ({}) => {
    const [message, setMessage] = useState('')

    return (<Container>
        <Modal>
            <Actions>
                <CancelButton>
                    <FontAwesomeIcon icon={faTimes}/>
                </CancelButton>
                <TextInput value="junpeifrance" onChange={(value) => {}}/>
                <AddButton>
                    <FontAwesomeIcon icon={faPlus}/>
                </AddButton>
                <ApplyButton>
                    <FontAwesomeIcon icon={faChevronRight}/>
                </ApplyButton>
            </Actions>
            <Message>{message}</Message>
        </Modal>
    </Container>)
}

const Container = styled.div`
    ${tw`fixed inset-0 z-50 bg-black/75 p-8 backdrop-blur hidden`}
`
const Modal = styled.div`
    ${tw`bg-purple-300 w-2/4 rounded-md drop-shadow-md m-auto p-3`}
`
const Actions = styled.div`
    ${tw`flex gap-1`}
`
const CancelButton = styled.div`
    ${tw`bg-slate-300 rounded-md py-1 px-2`}
`
const ApplyButton = styled.div`
    ${tw`bg-twitch rounded-md py-1 px-2`}
`
const AddButton = styled.div`
    ${tw`bg-lime-300 rounded-md py-1 px-2`}
`
const TextInput = styled.input`
    ${tw`rounded-md border bg-slate-100 border-slate-500 px-2 py-1 w-full`}
`
const Message = styled.div`
    ${tw`text-center`}
`

export default CustomPlayerSettings