import Menu from './components/Menu'
import {useState} from 'react';
import Player from "./components/Player";
import Clusters from "./components/Clusters";
import CustomPlayerSettings from "./components/CustomPlayerSettings";

function App() {
    const [selectedCluster, setSelectedCluster] = useState(null)
    const [lives, setLives] = useState([])
    const [chat, setChat] = useState('')
    const [layout, setLayout] = useState({})

    const layouts = {
        1: [{grid: '', items: []}],
        2: [{grid: 'grid-cols-2', items: []}, {grid: 'grid-rows-2', items: []}],
        3: [{
            grid: 'grid-cols-2 grid-rows-2', items: ['col-span-2', '', '']
        }, {
            grid: 'grid-cols-2 grid-rows-2', items: ['', 'row-span-2', '']
        }, {
            grid: 'grid-cols-2 grid-rows-2', items: ['', '', 'col-span-2']
        }, {
            grid: 'grid-cols-2 grid-rows-2', items: ['row-span-2', '', '']
        }],
        4: [{
            grid: 'grid-cols-2 grid-rows-2', items: []
        }, {
            grid: 'grid-cols-3 grid-rows-5', items: ['col-span-3 row-span-3', 'row-span-2', 'row-span-2', 'row-span-2']
        }],
        5: [{
            grid: 'grid-cols-6 grid-rows-5',
            items: ['col-span-3 row-span-3', 'col-span-3 row-span-3', 'col-span-2 row-span-2', 'col-span-2 row-span-2', 'col-span-2 row-span-2']
        }, {
            grid: 'grid-cols-4 grid-rows-3', items: ['col-span-4 row-span-2', '', '', '', '']
        }],
        6: [{
            grid: 'grid-cols-3 grid-rows-2', items: []
        }, {
            grid: 'grid-cols-4 grid-rows-3', items: ['col-span-2 row-span-2', 'col-span-2 row-span-2', '', '', '', '']
        }],
        7: [{
            grid: 'grid-cols-4 grid-rows-3',
            items: ['col-span-2 row-span-2', 'col-span-2', 'col-span-2', '', '', '', '']
        }],
        8: [{
            grid: 'grid-cols-6 grid-rows-4',
            items: ['col-span-4 row-span-2', '', '', '', '', 'col-span-2 row-span-2', 'col-span-2 row-span-2', 'col-span-2 row-span-2']
        }, {
            grid: 'grid-cols-6 grid-rows-6',
            items: ['col-span-3 row-span-4', 'col-span-3 row-span-4', 'row-span-2', 'row-span-2', 'row-span-2', 'row-span-2', 'row-span-2', 'row-span-2']
        }, {
            grid: 'grid-cols-15 grid-rows-2',
            items: ['col-span-5', 'col-span-5', 'col-span-5', 'col-span-3', 'col-span-3', 'col-span-3', 'col-span-3', 'col-span-3']
        }, {grid: 'grid-cols-4 grid-rows-2', items: []}],
        9: [{grid: 'grid-cols-3 grid-rows-3', items: []}],
        10: [{grid: 'grid-cols-5 grid-rows-2', items: []}],
        11: [{grid: 'grid-cols-4 grid-rows-3', items: []}],
        12: [{grid: 'grid-cols-4 grid-rows-3', items: []}],
        13: [{grid: 'grid-cols-4 grid-rows-4', items: []}],
        14: [{grid: 'grid-cols-4 grid-rows-4', items: []}],
        15: [{grid: 'grid-cols-4 grid-rows-4', items: []}],
        16: [{grid: 'grid-cols-4 grid-rows-4', items: []}],
        17: [{grid: 'grid-cols-5 grid-rows-4', items: []}],
        18: [{grid: 'grid-cols-5 grid-rows-4', items: []}],
        19: [{grid: 'grid-cols-5 grid-rows-4', items: []}],
        20: [{grid: 'grid-cols-5 grid-rows-4', items: []}],
        21: [{grid: 'grid-cols-6 grid-rows-4', items: []}],
        22: [{grid: 'grid-cols-6 grid-rows-4', items: []}],
        23: [{grid: 'grid-cols-6 grid-rows-4', items: []}],
        24: [{grid: 'grid-cols-6 grid-rows-4', items: []}],
        25: [{grid: 'grid-cols-5 grid-rows-5', items: []}],
        26: [{grid: 'grid-cols-6 grid-rows-5', items: []}],
        27: [{grid: 'grid-cols-6 grid-rows-5', items: []}],
        28: [{grid: 'grid-cols-6 grid-rows-5', items: []}],
        29: [{grid: 'grid-cols-6 grid-rows-5', items: []}],
        30: [{grid: 'grid-cols-6 grid-rows-5', items: []}],
        31: [{grid: 'grid-cols-6 grid-rows-6', items: []}],
        32: [{grid: 'grid-cols-6 grid-rows-6', items: []}],
        33: [{grid: 'grid-cols-6 grid-rows-6', items: []}],
        34: [{grid: 'grid-cols-6 grid-rows-6', items: []}],
        35: [{grid: 'grid-cols-6 grid-rows-6', items: []}],
        36: [{grid: 'grid-cols-6 grid-rows-6', items: []}],
        37: [{grid: 'grid-cols-7 grid-rows-6', items: []}],
        38: [{grid: 'grid-cols-7 grid-rows-6', items: []}],
        39: [{grid: 'grid-cols-7 grid-rows-6', items: []}],
        40: [{grid: 'grid-cols-7 grid-rows-6', items: []}],
        41: [{grid: 'grid-cols-7 grid-rows-6', items: []}],
        42: [{grid: 'grid-cols-7 grid-rows-6', items: []}],
        43: [{grid: 'grid-cols-8 grid-rows-6', items: []}],
        44: [{grid: 'grid-cols-8 grid-rows-6', items: []}],
        45: [{grid: 'grid-cols-8 grid-rows-6', items: []}],
        46: [{grid: 'grid-cols-8 grid-rows-6', items: []}],
        47: [{grid: 'grid-cols-8 grid-rows-6', items: []}],
        48: [{grid: 'grid-cols-8 grid-rows-6', items: []}],
        50: [{grid: 'grid-cols-10 grid-rows-5', items: []}],
    }

    return (<div className="fixed inset-0 flex flex-col bg-gradient-to-t from-slate-900 to-violet-900 overflow-y-auto">
        <CustomPlayerSettings/>
        <Clusters selected={selectedCluster} setSelected={setSelectedCluster}/>
        <Player layouts={layouts} layout={layout} lives={lives} chat={chat}/>
        <Menu cluster={selectedCluster} setCluster={setSelectedCluster}
              lives={lives} setLives={setLives}
              chat={chat} setChat={setChat}
              layout={layout} setLayout={setLayout} layouts={layouts}/>

        <div className="hidden" title="avoiding css purge">
            <span className="bg-slate-50"/>
            <span className="bg-gray-50"/>
            <span className="bg-zinc-50"/>
            <span className="bg-neutral-50"/>
            <span className="bg-stone-50"/>
            <span className="bg-red-50"/>
            <span className="bg-orange-50"/>
            <span className="bg-amber-50"/>
            <span className="bg-yellow-50"/>
            <span className="bg-lime-50"/>
            <span className="bg-green-50"/>
            <span className="bg-emerald-50"/>
            <span className="bg-teal-50"/>
            <span className="bg-cyan-50"/>
            <span className="bg-sky-50"/>
            <span className="bg-blue-50"/>
            <span className="bg-indigo-50"/>
            <span className="bg-violet-50"/>
            <span className="bg-purple-50"/>
            <span className="bg-fuchsia-50"/>
            <span className="bg-pink-50"/>
            <span className="bg-rose-50"/>
            <span className="bg-slate-100"/>
            <span className="bg-gray-100"/>
            <span className="bg-zinc-100"/>
            <span className="bg-neutral-100"/>
            <span className="bg-stone-100"/>
            <span className="bg-red-100"/>
            <span className="bg-orange-100"/>
            <span className="bg-amber-100"/>
            <span className="bg-yellow-100"/>
            <span className="bg-lime-100"/>
            <span className="bg-green-100"/>
            <span className="bg-emerald-100"/>
            <span className="bg-teal-100"/>
            <span className="bg-cyan-100"/>
            <span className="bg-sky-100"/>
            <span className="bg-blue-100"/>
            <span className="bg-indigo-100"/>
            <span className="bg-violet-100"/>
            <span className="bg-purple-100"/>
            <span className="bg-fuchsia-100"/>
            <span className="bg-pink-100"/>
            <span className="bg-rose-100"/>
            <span className="bg-slate-200"/>
            <span className="bg-gray-200"/>
            <span className="bg-zinc-200"/>
            <span className="bg-neutral-200"/>
            <span className="bg-stone-200"/>
            <span className="bg-red-200"/>
            <span className="bg-orange-200"/>
            <span className="bg-amber-200"/>
            <span className="bg-yellow-200"/>
            <span className="bg-lime-200"/>
            <span className="bg-green-200"/>
            <span className="bg-emerald-200"/>
            <span className="bg-teal-200"/>
            <span className="bg-cyan-200"/>
            <span className="bg-sky-200"/>
            <span className="bg-blue-200"/>
            <span className="bg-indigo-200"/>
            <span className="bg-violet-200"/>
            <span className="bg-purple-200"/>
            <span className="bg-fuchsia-200"/>
            <span className="bg-pink-200"/>
            <span className="bg-rose-200"/>
            <span className="bg-slate-300"/>
            <span className="bg-gray-300"/>
            <span className="bg-zinc-300"/>
            <span className="bg-neutral-300"/>
            <span className="bg-stone-300"/>
            <span className="bg-red-300"/>
            <span className="bg-orange-300"/>
            <span className="bg-amber-300"/>
            <span className="bg-yellow-300"/>
            <span className="bg-lime-300"/>
            <span className="bg-green-300"/>
            <span className="bg-emerald-300"/>
            <span className="bg-teal-300"/>
            <span className="bg-cyan-300"/>
            <span className="bg-sky-300"/>
            <span className="bg-blue-300"/>
            <span className="bg-indigo-300"/>
            <span className="bg-violet-300"/>
            <span className="bg-purple-300"/>
            <span className="bg-fuchsia-300"/>
            <span className="bg-pink-300"/>
            <span className="bg-rose-300"/>
            <span className="bg-slate-400"/>
            <span className="bg-gray-400"/>
            <span className="bg-zinc-400"/>
            <span className="bg-neutral-400"/>
            <span className="bg-stone-400"/>
            <span className="bg-red-400"/>
            <span className="bg-orange-400"/>
            <span className="bg-amber-400"/>
            <span className="bg-yellow-400"/>
            <span className="bg-lime-400"/>
            <span className="bg-green-400"/>
            <span className="bg-emerald-400"/>
            <span className="bg-teal-400"/>
            <span className="bg-cyan-400"/>
            <span className="bg-sky-400"/>
            <span className="bg-blue-400"/>
            <span className="bg-indigo-400"/>
            <span className="bg-violet-400"/>
            <span className="bg-purple-400"/>
            <span className="bg-fuchsia-400"/>
            <span className="bg-pink-400"/>
            <span className="bg-rose-400"/>
            <span className="bg-slate-500"/>
            <span className="bg-gray-500"/>
            <span className="bg-zinc-500"/>
            <span className="bg-neutral-500"/>
            <span className="bg-stone-500"/>
            <span className="bg-red-500"/>
            <span className="bg-orange-500"/>
            <span className="bg-amber-500"/>
            <span className="bg-yellow-500"/>
            <span className="bg-lime-500"/>
            <span className="bg-green-500"/>
            <span className="bg-emerald-500"/>
            <span className="bg-teal-500"/>
            <span className="bg-cyan-500"/>
            <span className="bg-sky-500"/>
            <span className="bg-blue-500"/>
            <span className="bg-indigo-500"/>
            <span className="bg-violet-500"/>
            <span className="bg-purple-500"/>
            <span className="bg-fuchsia-500"/>
            <span className="bg-pink-500"/>
            <span className="bg-rose-500"/>
            <span className="bg-slate-600"/>
            <span className="bg-gray-600"/>
            <span className="bg-zinc-600"/>
            <span className="bg-neutral-600"/>
            <span className="bg-stone-600"/>
            <span className="bg-red-600"/>
            <span className="bg-orange-600"/>
            <span className="bg-amber-600"/>
            <span className="bg-yellow-600"/>
            <span className="bg-lime-600"/>
            <span className="bg-green-600"/>
            <span className="bg-emerald-600"/>
            <span className="bg-teal-600"/>
            <span className="bg-cyan-600"/>
            <span className="bg-sky-600"/>
            <span className="bg-blue-600"/>
            <span className="bg-indigo-600"/>
            <span className="bg-violet-600"/>
            <span className="bg-purple-600"/>
            <span className="bg-fuchsia-600"/>
            <span className="bg-pink-600"/>
            <span className="bg-rose-600"/>
            <span className="bg-slate-700"/>
            <span className="bg-gray-700"/>
            <span className="bg-zinc-700"/>
            <span className="bg-neutral-700"/>
            <span className="bg-stone-700"/>
            <span className="bg-red-700"/>
            <span className="bg-orange-700"/>
            <span className="bg-amber-700"/>
            <span className="bg-yellow-700"/>
            <span className="bg-lime-700"/>
            <span className="bg-green-700"/>
            <span className="bg-emerald-700"/>
            <span className="bg-teal-700"/>
            <span className="bg-cyan-700"/>
            <span className="bg-sky-700"/>
            <span className="bg-blue-700"/>
            <span className="bg-indigo-700"/>
            <span className="bg-violet-700"/>
            <span className="bg-purple-700"/>
            <span className="bg-fuchsia-700"/>
            <span className="bg-pink-700"/>
            <span className="bg-rose-700"/>
            <span className="bg-slate-800"/>
            <span className="bg-gray-800"/>
            <span className="bg-zinc-800"/>
            <span className="bg-neutral-800"/>
            <span className="bg-stone-800"/>
            <span className="bg-red-800"/>
            <span className="bg-orange-800"/>
            <span className="bg-amber-800"/>
            <span className="bg-yellow-800"/>
            <span className="bg-lime-800"/>
            <span className="bg-green-800"/>
            <span className="bg-emerald-800"/>
            <span className="bg-teal-800"/>
            <span className="bg-cyan-800"/>
            <span className="bg-sky-800"/>
            <span className="bg-blue-800"/>
            <span className="bg-indigo-800"/>
            <span className="bg-violet-800"/>
            <span className="bg-purple-800"/>
            <span className="bg-fuchsia-800"/>
            <span className="bg-pink-800"/>
            <span className="bg-rose-800"/>
            <span className="bg-slate-900"/>
            <span className="bg-gray-900"/>
            <span className="bg-zinc-900"/>
            <span className="bg-neutral-900"/>
            <span className="bg-stone-900"/>
            <span className="bg-red-900"/>
            <span className="bg-orange-900"/>
            <span className="bg-amber-900"/>
            <span className="bg-yellow-900"/>
            <span className="bg-lime-900"/>
            <span className="bg-green-900"/>
            <span className="bg-emerald-900"/>
            <span className="bg-teal-900"/>
            <span className="bg-cyan-900"/>
            <span className="bg-sky-900"/>
            <span className="bg-blue-900"/>
            <span className="bg-indigo-900"/>
            <span className="bg-violet-900"/>
            <span className="bg-purple-900"/>
            <span className="bg-fuchsia-900"/>
            <span className="bg-pink-900"/>
            <span className="bg-rose-900"/>
        </div>
    </div>);
}

export default App;
