import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faMessage } from '@fortawesome/free-regular-svg-icons'

const Stream = ({stream, lives, setLives, chat, setChat}) => {
    const isLive = lives.some((live) => live.id === stream.id)
    const hasLive = lives.length > 0

    const setAsLive = (event) => {
        setLives([stream])
        event.stopPropagation()
    }
    const addAsLive = (event) => {
        if (isLive) {
            setLives(lives.filter((live) => live.id !== stream.id))
        }
        if (!isLive) {
            setLives(lives.concat([stream]))
        }
        event.stopPropagation()
    }
    const setAsChat = (event) => {
        // noinspection JSUnresolvedVariable
        setChat(chat === stream.login ? '' : stream.login)
        event.stopPropagation()
    }

    // noinspection JSUnresolvedVariable
    return (<div onClick={setAsLive}
                 className={[
                     'relative cursor-pointer p-0.5 rounded-md m-1 select-none transition-all text-white',
                     'hover:bg-gradient-to-br from-transparent to-twitch-hover',
                     hasLive && 'w-15',
                     !hasLive && 'w-80 flex flex-col gap-1 justify-between',
                     isLive && 'bg-twitch-active',
                     !isLive && 'bg-twitch-alt-2'
                 ].join(' ')}>
        <div className="flex">
            <div style={{backgroundImage: `url(${stream.profile_image_url})`}}
                 className={[
                     'bg-no-repeat bg-cover bg-center bg-origin-border bg-slate-800',
                     'rounded-tl-md shrink-0 transition-all',
                     hasLive && 'w-10 h-10 rounded-br',
                     !hasLive && 'w-14 h-14 rounded-bl-md',
                 ].join(' ')}/>
            {hasLive && (
                <div className="flex flex-col grow pl-[2px]">
                    <div className={`grow align-middle`} onClick={addAsLive}>
                        <FontAwesomeIcon icon={faPlus}/>
                    </div>
                    <div className={`grow align-middle`} onClick={setAsChat}>
                        <FontAwesomeIcon icon={faMessage}/>
                    </div>
                </div>
            )}
            {!hasLive && (
                <div className="flex flex-col text-left pl-2">
                    <div className="text-lg leading-tight">{stream.display_name || ''}</div>
                    <div className="opacity-80 text-sm leading-none max-h-7 overflow-hidden text-ellipsis">{stream.title || ''}</div>
                </div>
            )}
        </div>
        {hasLive && (
            <div className={`overflow-hidden text-ellipsis w-full`}>
                {stream.display_name}
            </div>
        )}
    </div>)
}

export default Stream
